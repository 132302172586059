.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 2000;
    outline: none;
    
    @include mq(md) {
        // bottom: 20px;
    }
    
    &__container {
        position: relative;
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: auto;
            height: 90%;
            
            @include mq(md) {
                height: 100%;
            }
        }
    }

    &__cta {
        color: $blue;
        background: $gold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7vw;
        height: 7vw;
        min-width: 70px;
        min-height: 70px;
        font-size: 12px;
        font-family: $titleFont;
        text-transform: uppercase;
        text-decoration: none;
        font-weight: 600;
        display: none;

        @include mq(md) {
            font-size: 16px;
        }
    }

}
