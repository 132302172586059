.home {
    overflow: hidden;
    background: $blue url('/images/back-home.jpg') no-repeat center center / cover;
    
    &__container {
        height: 100vh;
        position: relative;
    }
    
    &__image {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        height: 50%;

        @include mq(md) {
            top: 50%;
            height: 70%;
        }

        img {
            height: 100%;
            width: auto;
        }
    }
}

.galeria {
  background: $mainColor;
  width: 100%;
    height: 100%;

  &__hero {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__slider {
    .slick-prev,
    .slick-next {
      background: transparentize($gold, 0.2);
      height: 50px;
      width: 50px;
      border-radius: 50%;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-top: 2px solid $mainColor;
        border-right: 2px solid $mainColor;
        position: absolute;
        
        top: calc(50% - 10px);
      }
    }

    .slick-next {
      &::before {
        transform: rotate(45deg);
        left: calc(50% - 15px);
      }
    }

    .slick-prev {
      &::before {
        transform: rotate(-135deg);
        left: calc(50% - 5px);
      }
    }
  }
  &__slide {
    width: 100%;
    height: 100%;

    &__image {
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include mq(md) {
          height: auto;
          width: 100%;
          min-height: 100%;
        }
      }
    }
  }
}
