.reserva {
    position: relative;
    padding-top: 50px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    @include mq(md) {
        padding-top: 100px;
    }

    &__form-wrapper {
        position: relative;
        height: 100%;
    }

    .container,
    .row,
    .col-24 {
        height: 100%;
    }
   
    &__container {
        @include trans;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        opacity: 0;
        transform: translate3d(-100%, -50%, 0);
        display: flex;
        overflow: hidden;
        flex-direction: column;
        justify-content: center;
        pointer-events: none;
        
        @include mq(md) {
            padding: 0 80px 0 0;
        }
        
        &.-active {
            opacity: 1;
            transform: translate3d(0, -50%, 0);
            pointer-events: all;
        }
        // &--details,
        // &--confirmation {

        // }
    }

    &__title {
        margin: 0;
        margin-bottom: 40px;
        text-transform: uppercase;
        color: $blue;
        font-size: 32px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;

        &:not(:last-of-type) {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid $lightGray;
        }
    }

    &__hours {
        border: none;
        height: 50px;
        width: 100%;
        background: $lightGray;
        color: $blue;
        padding: 0 10px;
        outline: none;

        // option {

        // }
    }

    &__group {
        width: auto;
        flex-grow: 2;
    }

    &__subtitle,
    &__label {
        @include trans;

        margin: 0;
        margin-bottom: 5px;
        text-transform: uppercase;
        color: $blue;
        font-size: 14px;

        &.js-wrong {
            color: $red;
        }
    }

    &__text {
        @include trans;

        margin: 0;
        font-size: 20px;
        text-transform: uppercase;
        color: $blue;

        &.js-wrong {
            color: $red;
        }
    }

    &__buttons {
        width: auto;
        display: flex;
        justify-content: space-between;
    }

    &__button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $gold;
        border: none;
        position: relative;
        
        &:first-child {
            margin-right: 10px;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        .-horizontal {
            width: 20px;
            height: 2px;
            background: $white;
        }

        .-vertical {
            width: 2px;
            height: 20px;
            background: $white;
        }

        &:disabled {
            background: $midGray;
            opacity: .7;
        }
    }

    &__date-button,
    &__reservar-button,
    &__continue-button,
    &__back-button {
        width: 100%;
        height: 50px;
        line-height: 50px;
        color: $blue;
        text-transform: uppercase;
        font-weight: 700;
        background: $gold;
        border: none;
        position: relative;
    }

    &__date-button {
        width: 100px;
        padding: 0 10px;
    }

    &__reservar-button,
    &__continue-button,
    &__back-button {
        @include trans;

        margin-top: 40px;
        padding-right: 20px;

        &:disabled {
            opacity: .5;
        }
    }

    button {
        outline: none;
        cursor: pointer;
    }

    &__back-button,
    &__reservar-button {
        width: 49%;
    }

    &__label {
        font-size: 20px;
    }
    
    &__input {
        margin-top: 10px;
        display: block;
        border: none;
        width: 100%;
        background: $lightGray;
        height: 50px;
        padding: 0 20px;
        color: $blue;
    }

    &__buttons-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__alert {
        @include trans;

        color: $red;
        font-weight: 700;
        opacity: 0;
        margin: 0;
        margin-top: 10px;
        font-size: 12px;

        @include mq(md) {
            font-size: 16px;
        }

        &.-active {
            opacity: 1;
        }
    }

    &__confirmation-text {
        color: $blue;
        text-align: left;
        font-size: 30px;

        @include mq(md) {
            font-size: 40px;
        }

        span {
            display: block;
            margin-top: 10px;
            color: $gold;
            font-weight: 700;
            font-size: 36px;
        }
    }

    &__col {
        background: $blue;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vw;
        width: 100%;
        
        @include mq(md) {
            height: 100%;
            position: absolute;
            right: 0;
            padding-top: 90px;
        }

        img {
            width: 80%;
            height: auto;

            @include mq(md) {
                width: auto;
            }
        }

        @include customMq(1200px) {
            width: calc(50% + 80px);
        }
        
        @include customMq(1400px) {
            width: calc(50% + 90px);
        }
    }

    + footer {
        display: none;
    }
}

input[type="date"] {
    outline: none;
    -webkit-appearance: none;

    &::-webkit-clear-button,
    &::-webkit-inner-spin-button {
        display: none;
    }

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 90%;
        height: 90%;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    &::-webkit-datetime-edit-fields-wrapper {
        width: 100%;
        text-align: center;
        pointer-events: none;
    }

    &::-webkit-datetime-edit-month-field {
        &:focus {
            background-color: $gold;
            color: $blue;
        }

    }
}