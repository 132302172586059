.carta {
    background: $blue;
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding-top: 70px;

    @include mq(md) {
        padding-top: 80px;
    }

    .container {
        position: relative;
    }
    
    &__container {
        padding-bottom: 170px;

        @include mq(md) {
            padding-bottom: 0;
        }
    }

    &__group {
        @include trans;

        opacity: 0;
        overflow: hidden;
        height: 0;

        &.-active {
            opacity: 1;
            height: auto;
            overflow: visible;
        }
    }
    
    &__subgroup {
        position: relative;
        
        @include mq(md) {
            border-top: 2px solid $gold;
        }

         &:after {
             content: '';
            display: none;

             @include mq(md) {
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                background: $gold;
                width: 2px;
            }
        }
    }

    &__group-wrapper {
        padding: 40px;
    }

    &__title {
        color: $gold;
        font-size: 50px;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
    }

    &__text {
        color: $white;
        font-size: 20px;
        padding: 0 35px;
        text-align: center;
        margin: 0;
        margin-bottom: 40px;

        &.-small {
            padding: 0;
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    &__option {
        border: 2px solid $gold;
        height: 80px;
        color: $gold;
        width: 100%;
        background: transparent;
        line-height: 80px;
        text-transform: uppercase;
        cursor: pointer;
    }

    &__subtitle {
        margin: 0;
        color: $gold;
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        margin-bottom: 20px;
    }

}

.-separation {
    margin-bottom: 30px;

    &--alt {
        margin-bottom: 30px;

        @include mq(md) {
            margin-bottom: 0;
        }
    }
}

.-arrow {
    @include trans;

    display: none;

    @include mq(md) {
        display: block;
        position: absolute;
        top: 50%;
        width: 20px;
        height: 20px;
        border-bottom: 3px solid $gold;
        opacity: 0;
        overflow: hidden;
        cursor: pointer;
    }

    &.-active {
        opacity: 1;
        overflow: visible;
    }
    
    &--prev {
        border-left: 3px solid $gold;
        left: 15px;
        transform: rotate(45deg) translateY(-50%);

    }

    &--next {
        border-right: 3px solid $gold;
        transform: rotate(-45deg) translateY(-50%);
        right: 15px;
    }
}

.-dots {
    @include trans;

    display: none;

    @include mq(md) {
        display: block;
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
    
    &.-active {
        opacity: 1;
        height: auto;
        overflow: visible;
    }
}

.-dot {
    @include trans;

    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid $gold;
    margin: 0 5px;

    &.-active {
        width: 12px;
        height: 12px;
        background: $gold;
    }
}

.-close-button {
    @include trans;

    position: relative;
    height: 20px;
    width: 20px;
    margin-left: auto;
    opacity: 0;
    cursor: pointer;

    &.-active {
        opacity: 1;
        z-index: 100;
    }

    span {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 2px;
        background: $white;
    }

    .-left {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    .-right {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
}