@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Regular.eot');
  src: url('/fonts/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Regular.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Regular.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Regular.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Medium.eot');
  src: url('/fonts/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Medium.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Medium.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Medium.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-LightItalic.eot');
  src: url('/fonts/BrandonGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-LightItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-LightItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-LightItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Light.eot');
  src: url('/fonts/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Light.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Light.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Light.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Light.svg#BrandonGrotesque-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Black.eot');
  src: url('/fonts/BrandonGrotesque-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Black.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Black.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Black.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Black.svg#BrandonGrotesque-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-ThinItalic.eot');
  src: url('/fonts/BrandonGrotesque-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-ThinItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-ThinItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-ThinItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-ThinItalic.svg#BrandonGrotesque-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-BlackItalic.eot');
  src: url('/fonts/BrandonGrotesque-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-BlackItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-BlackItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-BlackItalic.svg#BrandonGrotesque-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Bold.eot');
  src: url('/fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Bold.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Bold.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-MediumItalic.eot');
  src: url('/fonts/BrandonGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-MediumItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-MediumItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-MediumItalic.svg#BrandonGrotesque-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-Thin.eot');
  src: url('/fonts/BrandonGrotesque-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-Thin.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-Thin.woff') format('woff'),
      url('/fonts/BrandonGrotesque-Thin.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-Thin.svg#BrandonGrotesque-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-RegularItalic.eot');
  src: url('/fonts/BrandonGrotesque-RegularItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-RegularItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-RegularItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-RegularItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-RegularItalic.svg#BrandonGrotesque-RegularItalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('/fonts/BrandonGrotesque-BoldItalic.eot');
  src: url('/fonts/BrandonGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BrandonGrotesque-BoldItalic.woff2') format('woff2'),
      url('/fonts/BrandonGrotesque-BoldItalic.woff') format('woff'),
      url('/fonts/BrandonGrotesque-BoldItalic.ttf') format('truetype'),
      url('/fonts/BrandonGrotesque-BoldItalic.svg#BrandonGrotesque-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}


